import React from "react";
import styled from "styled-components";
import '../../assets/style.css';
import { device, deviceMin } from '../../assets/device';
import {AiOutlineMenu} from 'react-icons/ai';
import { NavLink as Link } from "react-router-dom";
const NavText = styled.a`
    color: white;
    font-family: Gotham_Medium;
    letter-spacing: 0.2em;
    border-bottom: 0px;
    text-decoration: none;
    padding-inline: 1.5em;

    &:hover {
        color: rgb(174,83,0);
    }

    @media ${device.tablet}{
        height: 3vh;
        width: auto;
    }
`;

const NavLogo = styled.img`
    height: 4vh;
    width: auto;

    @media ${device.tablet}{
        height: 3vh;
        width: auto;
    }
`;

const CustomNav = styled.div`

    @media ${device.tablet}{
        width: 100%;
    }
`;

const NavLink = styled(Link)`
color: #fff;
font-family: sassoon-sans-std-bold;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;
&:hover {
  color: #309E72;
}
`;

function CoursesNavbar() {
    return (
    
        <nav class="navbar navbar-expand-lg fixed-top fixingNav">
        <div class="container-fluid" style={{height: "100%"}}>
            <a class="navbar-brand" href="#Home">
                <NavLogo src={require("../../assets/images/logo.png")} />
            </a>

          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarOptions" aria-controls="navbarOptions" aria-expanded="false" aria-label="Toggle navigation" style={{color: "white", border: "none"}}>
            <AiOutlineMenu class="navbar-toggler-icon" style={{color: "white"}}></AiOutlineMenu>
          </button>

          <div class="collapse navbar-collapse justify-content-end fixingNavRes " id="navbarOptions">
            <ul class="nav navbar-nav navbar-right">
                    <li class="nav-item">
                        <NavText class="nav-link" href="/demo#Inicio">INICIO</NavText>
                    </li>
                    <li class="nav-item">
                        <NavText class="nav-link" href="/demo#Nosotros">NOSOTROS</NavText>
                    </li>
                    <li class="nav-item">
                        <NavText class="nav-link" href="/demo#Cursos">CURSOS</NavText>
                    </li>
                    <li class="nav-item">
                        <NavText class="nav-link" href="/demo#Contacto">CONTACTO</NavText>
                    </li>
                </ul>
          </div>



        </div>
      </nav>
    );

};

export default CoursesNavbar;