import React, { useEffect } from "react";
import styled from "styled-components";
import CoursesNavbar from "./coursesNavbar";
import { device } from '../../assets/device';
import BeigeBackground from "../../assets/images/beige-background.png";
import PurpleBackground from "../../assets/images/purple-background-big.png";
import { useLocation } from "react-router-dom";
import Footer from "../footer";

const CoursesHeader = styled.div`
    width: 100%;
    height: 45vh;
    padding: 0%;
    font-family: GothamBook;
    background-image: url(${BeigeBackground});

    @media ${device.tablet}{
        font-size: 0.5em;
    }
`;

const CourseInfo = styled.div`
    text-align: center;
    height: 25vh;
    padding-top: 10vh;
    background: rgb(30,2,50);
    color: white;

    @media ${device.laptopM}{
        height: 29vh;
    }

    @media ${device.tablet}{
        padding-top: 9vh;
    }
`;

const HeaderText = styled.p`

    font-size: 1.8em;
    color: white;
    line-height: 1.1em;
    font-family: GothamBook;

`;

const HeaderTitle = styled.p`

    font-size: 3.5em;
    color: white;
    line-height: 1.1em;
    font-family: GothamBook;
`;

const HomeContainer = styled.div`
    width: 100%;
    padding: 0%;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const HeaderBox = styled.div`

    background-color: rgb(30,2,50);
    margin: -12% 24% 0 24%;
    padding-inline: 7em;
    padding-top: 5em;
    height: 45vh;
    position: absolute;
    border-bottom: 2px solid white;
`;

const CoursesBox = styled.div`

    background-image: url(${PurpleBackground});
    margin: 0 0 0 0;
    padding-bottom: 4em;
`;

const CoursesList = styled.div`

    color: white;
    padding: 15% 24% 0 24%;

`;

const AboutImg = styled.img`

    text-align: right;
    padding-right: 1em;
    height: 35vh;
    width: 100%;
    object-fit: cover;
    border-radius: 0 5em 5em 5em;

`;

function Courses() {

    const location = useLocation();
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [location]);

  return (
        <HomeContainer class="container-fluid">
            <CoursesNavbar/>

            <CoursesHeader className="container-fluid">
               
            </CoursesHeader>

            

            <CoursesBox class="container-fluid">
                <HeaderBox class="container-fluid">
                            <HeaderTitle>NUESTROS <br/> CURSOS</HeaderTitle>
                            <br/>
                            <HeaderText>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</HeaderText>
                </HeaderBox>

                <CoursesList class="container-fluid">
                    
                    <div class="row" style={{borderBottom: "2px solid white", paddingBottom: "4em"}}>
                        <div class="col-md-6 col-sm-12 fixingAboutImg" >
                            <AboutImg src={require("../../assets/images/nosotros.jpg")}/>
                        </div>
                        <div class="col-6" style={{fontFamily: "GothamBook"}}>
                            <p style={{fontFamily: "Gotham_Medium", fontSize: "1.8em"}}>Título del Curso</p>
                            <p>Imparte</p>
                            <p>Fecha</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Costo</p>
                        </div>
                    </div>

                    <div class="row" style={{borderBottom: "2px solid white", paddingBottom: "4em", paddingTop: "4em"}}>
                        <div class="col-6" style={{fontFamily: "GothamBook"}}>
                            <p style={{fontFamily: "Gotham_Medium", fontSize: "1.8em"}}>Título del Curso</p>
                            <p>Imparte</p>
                            <p>Fecha</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Costo</p>
                        </div>
                        
                        <div class="col-md-6 col-sm-12 fixingAboutImg" >
                            <AboutImg src={require("../../assets/images/nosotros.jpg")}/>
                        </div>
                    </div>

                    <div class="row" style={{borderBottom: "2px solid white", paddingBottom: "4em", paddingTop: "4em"}}>
                        <div class="col-md-6 col-sm-12 fixingAboutImg" >
                            <AboutImg src={require("../../assets/images/nosotros.jpg")}/>
                        </div>
                        <div class="col-6" style={{fontFamily: "GothamBook"}}>
                            <p style={{fontFamily: "Gotham_Medium", fontSize: "1.8em"}}>Título del Curso</p>
                            <p>Imparte</p>
                            <p>Fecha</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Costo</p>
                        </div>
                    </div>

                    <div class="row" style={{borderBottom: "2px solid white", paddingBottom: "4em", paddingTop: "4em"}}>
                        <div class="col-6" style={{fontFamily: "GothamBook"}}>
                            <p style={{fontFamily: "Gotham_Medium", fontSize: "1.8em"}}>Título del Curso</p>
                            <p>Imparte</p>
                            <p>Fecha</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Costo</p>
                        </div>
                        
                        <div class="col-md-6 col-sm-12 fixingAboutImg" >
                            <AboutImg src={require("../../assets/images/nosotros.jpg")}/>
                        </div>
                    </div>

                    <div class="row" style={{borderBottom: "2px solid white", paddingBottom: "4em", paddingTop: "4em"}}>
                        <div class="col-md-6 col-sm-12 fixingAboutImg" >
                            <AboutImg src={require("../../assets/images/nosotros.jpg")}/>
                        </div>
                        <div class="col-6" style={{fontFamily: "GothamBook"}}>
                            <p style={{fontFamily: "Gotham_Medium", fontSize: "1.8em"}}>Título del Curso</p>
                            <p>Imparte</p>
                            <p>Fecha</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            <p>Costo</p>
                        </div>
                    </div>

                </CoursesList>
            </CoursesBox>

            <Footer/>

        </HomeContainer>
  );
}

export default Courses;
