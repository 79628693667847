import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './views/home';
import Placeholder from './views/placeholder';
import Courses from './views/Courses/courses';
import AboutUs from './views/About Us/aboutUs';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path='/' element={<Placeholder/>} /> */}
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/cursos' element={<Courses/>} />
        <Route exact path='/nosotros' element={<AboutUs/>} />
      </Routes>
    </Router>
  );
}

export default App;
