export const Cursos = [
    {id: 1, title: "Defensa Fiscal", img: "defensa"},
    {id: 2, title: "Gestión Contable y Fiscal para Empresas", img: "contable"},
    {id: 3, title: "Excel para Contadores", img: "excel"},
    {id: 4, title: "Ambiente y Clima Laboral", img: "ambiente"},
    {id: 5, title: "Requisitos Fiscales en Personas Físicas y Morales", img: "requisitos"},
    {id: 6, title: "Trabajo en Equipo", img: "equipo1"},
    {id: 7, title: "Habilidades para Hablar en Público", img: "hablar"},
    {id: 8, title: "Primeros Auxilios", img: "auxilios"},
    {id: 9, title: "Inducción a la Seguridad y Salud en el trabajo", img: "seguridad"},
    {id: 10, title: "Atención al Cliente e Imagen Profesional", img: "cliente"},
    {id: 11, title: "Autoconocimiento y Liderazgo en el Trabajo", img: "liderazgo"},
    {id: 12, title: "Identificación y Solución de Problemas Laborales", img: "problemas"},
    {id: 13, title: "CFDI 3.3 REP y Nuevo Proceso de Cancelación", img: "cancelacion"},
    {id: 14, title: "CONTPAQi: Contabilidad", img: "contpaq1"},
    {id: 15, title: "CONTPAQi: Facturas", img: "contpaq2"},
    {id: 16, title: "Transparencia, Acceso a la Información Pública y Protección de Datos Personales", img: "transparencia"},
    {id: 17, title: "Toma de Decisiones", img: "decisiones"},
    {id: 18, title: "Emisión de CFDI para Emprendedores", img: "cfdi"},
    {id: 19, title: "Ética y Valores para la Productividad", img: "etica"},
    {id: 20, title: "Uso de Hojas de Cálculo de Excel para Administradores", img: "excel2"},
    {id: 21, title: "Facilitador en Cultura de la Legalidad", img: "legalidad"},
    {id: 22, title: "Inducción a la Seguridad Pública y Prevención del Delito", img: "delito"},
    {id: 23, title: "Inducción a las Normas Oficiales Mexicanas", img: "normas"},
    {id: 24, title: "Interpretación de las Señales de Seguridad e Higiene en los Centros de Trabajo", img: "senales"},
    {id: 25, title: "Introducción a la Administración de los Recursos Humanos", img: "humanos"},
    {id: 26, title: "Las Finanzas para la Toma de Decisiones en la Empresa", img: "finanzas"},
    {id: 27, title: "Liderazgo en los Equipos", img: "equipo2"},
    {id: 28, title: "Sistema de Gestión Integral (ISO 45001, ISO 14001, ISO 9001)", img: "iso"},
];
