
import React, { useEffect } from "react";
import styled from "styled-components";
import CoursesNavbar from "./coursesNavbar";
import { device } from '../../assets/device';
import AboutUsBackground from "../../assets/images/about-us-background.png";
import AboutUsBackgroundPurple from "../../assets/images/about-us-background-purple.png";
import Footer from "../footer";
import { useLocation } from "react-router-dom";

const AboutUsHeader = styled.div`
    width: 100%;
    height: 100%;
    padding: 0%;
    font-family: GothamBook;
    color: rgb(30,2,50);
    background-image: url(${AboutUsBackground});

    @media ${device.laptopM}{
        height: 100%;
    }
`;

const HomeContainer = styled.div`
    width: 100%;
    padding: 0%;
    overflow-x: hidden;
    overflow-y: hidden;
`;

const AboutUsInfo = styled.div`
    background-image: url(${AboutUsBackgroundPurple});
    margin: 0%;
    padding: 5% 7% 3% 7%;
`;

const AboutUsItem = styled.p`
    font-size: 1em;
    font-family: GothamBook;
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 2em;
`;

const AboutUsTitle = styled.p`
    text-align: center;
    font-size: 3em;
    font-family: Gotham_Medium;
    @media ${device.tablet}{
        padding-top: 2em;
        font-size: 1.8em;
    }
    
`;

const AboutUsText = styled.p`
    text-align: center;
    font-size: 1.3em;
    font-family: GothamBook;
    margin-bottom: 0%;
    padding-bottom: 5em;

    @media ${device.laptopM}{
        padding-bottom: 4em;
    }

    @media ${device.tablet}{
        font-size: 1em;
        
        padding-bottom: 3em;
    }
`;


function AboutUs() {

    const location = useLocation();
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [location]);

  return (
        <HomeContainer class="container-fluid">
            <CoursesNavbar/>


            <AboutUsHeader className="container-fluid">
                    <div style={{padding: "10% 20% 0% 20%"}}>
                        <AboutUsTitle>Nuestra misión</AboutUsTitle>
                        <br/>
                        <AboutUsText>
                        Pugnar mediante la unión y representación de empresarios afiliados voluntariamente, por cultura de excelencia empresarial, el establecimiento de una economía de mercado con responsabilidad social y la participación ciudadana, así como el estudio, mejoramiento, defensa de nuestros respectivos intereses, para la edificación de un México más justo y humano, de la verdad y del derecho y, por lo tanto, más apto para la consecución del bien común de nuestro sindicato patronal.
                        </AboutUsText>  
                    </div>
                   
            </AboutUsHeader>
            
            <AboutUsInfo class="container-fluid">
                <div class="row" style={{paddingInline: "10%"}}>

                    <div class="col-12" style={{color: "white",padding: "0% 0% 0% 0%"}}>
                            <p style={{fontFamily: "Gotham_Medium", fontSize: "2em", color: "white", paddingBottom: "3%"}}>Valores</p>

                            <AboutUsItem>La lucha de clases como móvil de proceso histórico</AboutUsItem>
                            <AboutUsItem>La organización sistemática para eliminar los obstáculos que se opongan al progreso de las empresas a las que representan los patrones conformantes del sindicato.</AboutUsItem>
                            <AboutUsItem>La democracia y autonomía sindical.</AboutUsItem>
                            <AboutUsItem>La no intervención del sindicato en procesos electorales.</AboutUsItem>
                            <AboutUsItem>Igualdad sustantiva entre mujeres y hombres</AboutUsItem>
                            <AboutUsItem>La participación abierta, respetuosa y directa de todos sus miembros en la toma de decisiones de la organización.</AboutUsItem>
                    </div>
                </div>

                <br/>
                <br/>
                <br/>

                <div class="row" style={{paddingInline: "10%"}}>

                    <div class="col-12" style={{color: "white",padding: "0% 0% 0% 0%"}}>
                        <p style={{fontFamily: "Gotham_Medium", fontSize: "2em", color: "white", paddingBottom: "3%"}}>Objetivos</p>

                        <AboutUsItem>Procurar armonizar las relaciones de los patrones entre sí y de éstos con los trabajadores.</AboutUsItem>
                        <AboutUsItem>El estudio, mejoramiento y defensa de los respectivos intereses de nuestros agremiados.</AboutUsItem>
                        <AboutUsItem>Estudiar, para beneficio de sus asociados y para la realización del bienestar general, los problemas sociales derivados de las relaciones entre patrones y trabajadores.</AboutUsItem>
                        <AboutUsItem>Proporcionar a los empresarios, y cuando se juzgue conveniente a los trabajadores y a las autoridades, el resultado de los estudios a que se refiere la fracción anterior, con el propósito de lograr el progreso y bienestar general, y la prosperidad nacional.</AboutUsItem>
                        <AboutUsItem>Estudiar y divulgar por medio de cursos, conferencias, semanarios, boletines y, en general, por todos los medios posibles, las técnicas de la organización científica, de la administración de personal y toda clase de conocimientos que tiendan a mejorar las relaciones entre patrones, jefes y trabajadores, a elevar los índices de productividad y a lograr el desarrollo social y económico del país.</AboutUsItem>
                            
                    </div>
                </div>

            </AboutUsInfo>

            <Footer/>


        </HomeContainer>
  );
}

export default AboutUs;
