import React from "react";
import styled from "styled-components";
import { device, deviceMin } from '../assets/device';

const FooterContainer = styled.div`
    height: 13vh;
    width: 100%;
    padding: 0%;
    background: rgb(174,83,0);
    color: rgb(217,192,185);
    text-align: center;
    font-family: GothamBook;
    font-size: 1.2em;
    padding-top: 3em;

    @media ${device.tablet}{
        font-size: 0.9em;
        height: 12vh;
    }

    @media ${device.laptopM}{
        font-size: 1em;
        padding-top: 2em;
    }

`;

function Footer(){
    return(
        <FooterContainer id="Footer" class="container-fluid">
            <div class="row">
                <div class="col-12">
                    © 2022 Apipo. Powered by Hypnos Media.
                </div>
            </div>
        </FooterContainer>
    );
};

export default Footer;