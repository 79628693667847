import React from "react";
import styled from "styled-components";
import PlaceholderBackground from "../assets/images/placeholder1.png";
import PlaceholderBackgroundLaptop from "../assets/images/placeholder-laptop.png";
import PlaceholderBackgroundPhone from "../assets/images/placeholder-phone.png";
import { device } from '../assets/device';

const PlaceholderContainer = styled.div`
    height: 100vh;
    overflow: hidden;

`;

const PlaceholderImg = styled.img`
    object-fit: contain;
    width: 100%;
    height: auto;
`;

function Placeholder(){
    return(
        <PlaceholderContainer class="container-fluid">
            <picture>
                <source
                  media="(max-width: 768px)"
                  srcSet={require("../assets/images/placeholder-phone.png")}
                />
                <source
                  media="(max-width: 1366px)"
                  srcSet={require("../assets/images/placeholder-laptop.png")}
                />
                <PlaceholderImg src={require("../assets/images/placeholder1.png")} />
              </picture>
        </PlaceholderContainer>
    );
};

export default Placeholder