
import React, { useEffect } from "react";
import styled from "styled-components";
import AboutUs from "./aboutUs";
import ViewCards from "./cards";
import HomeCarousel from "./carousel";
import Contact from "./contact";
import Footer from "./footer";
import HomeNavbar from "./navbar";
import { device, deviceMin } from '../assets/device';
import { useLocation } from "react-router-dom";


const QuoteRibbon = styled.div`
    width: 100%;
    padding: 0%;
    font-family: GothamBook;
    @media ${device.tablet}{
        font-size: 0.5em;
    }
`;

const QuoteInfo = styled.div`
    text-align: center;
    height: 25vh;
    padding-top: 10vh;
    background: rgb(30,2,50);
    color: white;
    font-style: italic;

    @media ${device.laptopM}{
        height: 29vh;
    }

    @media ${device.tablet}{
        padding-top: 9vh;
    }
`;

const QuoteText = styled.h4`
    
    @media ${device.laptopM}{
        font-size: 1.5em;
        padding-inline: 2em;
    }

    @media ${device.tablet}{
        font-size: 2em;
        padding-inline: 2em;
    }
`;

const HomeContainer = styled.div`
    width: 100%;
    padding: 0%;
    overflow-x: hidden;
    overflow-y: hidden;
`;

function Home() {

    const {hash, key} = useLocation();
    useEffect(()=>{
    if(hash){
       const targetElement = document.getElementById(hash.substring(1))
        targetElement?.scrollIntoView({behavior: 'smooth'})
    }
    }, [key, hash])
    

  return (
      <HomeContainer class="container-fluid">
        <HomeNavbar/>
        
        <HomeCarousel/>
        
        <AboutUs/>

        <QuoteRibbon className="container-fluid">
            <QuoteInfo class="row align-items-center">
                <div class="col-12">
                    <QuoteText>"Creemos firmemente en hacer lo correcto para nuestros empleados, nuestros agremiados y nuestras comunidades."</QuoteText>
                </div>
            </QuoteInfo>
        </QuoteRibbon>
        
        <ViewCards/>

        <Contact/>

       <Footer/>


      </HomeContainer>
  );
}

export default Home;
