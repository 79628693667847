import React, { useEffect } from "react";
import '../assets/style.css';
import { device, deviceMin } from '../assets/device';
import BeigeBackground from "../assets/images/beige-background-big.png";
import CardsBackgroundRes from "../assets/images/cards-background-responsive.png";
import { NavLink as Link } from "react-router-dom";

import styled from "styled-components";
import { Cursos } from "../assets/cursos";

const CardItemContainer = styled.div`
    padding: 0 1em 0 1em;
    margin-bottom: 2em;
    height: 50vh;
    color: white;
    
    @media ${device.tablet}{
        padding: 0em 0em 0em 0em;
        text-align: center;
    }
`;

const CardsContainer = styled.div`
    width: 100%;
    padding: 2em 8em 5em 8em;
    background-image: url(${BeigeBackground});

    @media ${device.tablet}{
        padding: 2em 0em 5em 0em;
        background-image: url(${CardsBackgroundRes});
        }
    }
`;

const CardItem = styled.div`
    position: relative;
    display:inline-block;
`;

const CardDetails = styled.div`
    padding-top: 40px;
    transition: all 0.5s ease 0s;
`;


const CardImage = styled.img`
    object-fit: cover;
    object-position: center;
    height: 50vh;
    width: 40vh;
    border-radius: 3em 3em 0em 3em;


`;

const OverlayInfo = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media ${device.tablet}{
        padding-top: 5em;
    }
`;

const OverlayText = styled.span`
    position: absolute;
    text-align: center;
    padding-top: 10em;
    padding-inline: 3em;
    width: 100%;
    height: 100%;

    &:hover{
        transition: all 1.5s;
        padding-top: 6em;
    }

    @media ${device.tablet}{
        position: initial;
        padding-top: 0em;
    }

    @media ${device.laptopM}{
        padding-top: 3em;
        &:hover{
            transition: all 1.5s;
            padding-top: 4.5em;
        }
    }
`;

const OverlayTitleRes = styled.h2`
    @media ${device.laptopM}{
        font-size: 1.4em;
    }
    @media ${device.tablet}{
        font-size: 1.5em;
        padding-inline: 1em;
    }
`;

const OverlayDescriptionRes = styled.p`
    padding-top: 2em;
    @media ${device.laptopM}{
        font-size: 0.9em;
    }
`;

const CardsTitle = styled.p`

    font-size: 4.5em;
    font-family: Gotham_Medium;
    color: rgb(30,2,50);

    @media ${device.laptopM}{
        font-size: 2.5em;
        margin-bottom: 0em;
    }

    @media ${device.tablet}{
        text-align: center;
        font-size: 2.5em;
        margin-bottom: 0em;

    }

`;

const CardsButton = styled(Link)`
    color: rgb(30,2,50);
    background: white;
    padding: 0.8em 3em 0.8em 3em;
    border-radius: 1.5em;
    font-size: 1em;
    border: 0px;
    font-family: Gotham_Medium;
    text-decoration: none;
    &:hover {
        background: rgb(30,2,50);
        border: 0px;
        color: white;
    }
`;

{

    //BUTTON
    //<CardsButton to="/demo/cursos">Ver más</CardsButton>

}


function ViewCards(){

    const render = () => {
        return(

            Cursos.map( (curso) => {
                return(
                    <CardItemContainer class="col">
                    <CardDetails>
                        <CardItem>
                            <CardImage src={require('../assets/images/courses/'+curso.img+'.png')}/>
                            <OverlayInfo>
                                <OverlayText>                                
                                    <OverlayTitleRes>{curso.title}</OverlayTitleRes>
                                    <OverlayDescriptionRes></OverlayDescriptionRes>
                                </OverlayText>
                            </OverlayInfo>
                        </CardItem>
                    </CardDetails>
                </CardItemContainer>
                );
            }

            )
        )
    }

    useEffect(()=>{

        console.log(Cursos)

    });

    return(
        <CardsContainer id="Cursos" className="container-fluid">
            <CardsTitle>Cursos</CardsTitle>
            <div class="row row-cols-1 row-cols-md-4 row-cols-sm-1 g-4">

            {render()}

            </div>

    </CardsContainer>

    );
};

export default ViewCards;