import React from "react";
import styled from "styled-components";
import {BsFacebook} from "react-icons/bs";
import {SiInstagram} from "react-icons/si";
import { device, deviceMin } from '../assets/device';
import '../assets/style.css';

const ContactConatiner = styled.div`
    width: 100%;
    padding: 0%;
    background: rgb(30,2,50);
    color: white;
    font-family: Gotham_Medium;

    @media ${device.laptopM}{
        height: 70vh;
    }


    @media ${device.tablet}{
        height: 100vh;
    }
`;

const MapContainer = styled.iframe`
    width: 60vh;
    height: 40vh;
    border: 0px;

    @media ${device.tablet}{
        height: 40vh;
        width: 40vh;
    }
`;

const ContactTitle = styled.p`
    font-size: 3.5em;
    font-weight: bold;
    letter-spacing: 0.1em;

    @media ${device.laptopM}{
        margin-left: 0%;
        padding-top: 0em;
        margin-top: -1em;
        font-size: 2.5em;
    }

    @media ${device.tablet}{
        margin-left: 0%;
        padding-top: 0em;
        margin-top: -0.5em;
        font-size: 2.5em;
    }

`;

const ContactInfo = styled.p`
    font-family: GothamBook;
    font-size: 1.5em;
    line-height: 1em;
   
    @media ${device.laptopM}{
        font-size: 1.3em;
    }

    @media ${device.tablet}{
        margin-left: 0%;
        font-size: 1.2em;
    }
`;

const ContactInfoSmall = styled.p`
    font-family: GothamBook;
    font-size: 1.3em;
    line-height: 1em;

    @media ${device.laptopM}{
        font-size: 1.2em;
    }

    @media ${device.tablet}{
        margin-left: 0%;
        font-size: 1em;
    }

    
`;

const SocialsInfo = styled.a`
    color: white;
    font-size: 1.5em;
    text-decoration: none;
    &:hover {
        color: rgb(174,83,0);
    }

    @media ${device.laptopM}{
        font-size: 1.3em;
    }

    @media ${device.tablet}{
        font-size: 1.1em;
    }
`;

const StyledBsFacebook = styled(BsFacebook)`
    color: white;
  &:hover {
    color: rgb(174,83,0);
  }
    size:25;
`;

const StyledSiInstagram = styled(SiInstagram)`
color: white;
  &:hover {
    color: rgb(174,83,0);
  }
  size:25;
`;

function Contact(){
    return(
        <ContactConatiner id="Contacto" className="container-fluid">
            <div class="row align-items-center" style={{textAlign: "center", height: "65vh"}}>
                


                <div class="col-md-6 col-sm-12 fixingContactInfo">
                    <div class="row align-items-center">
                        <div class="col-12" style={{paddingTop: "3em"}}>
                            <ContactTitle>CONTACTO</ContactTitle>
                            <ContactInfoSmall>
                                <b>Matriz:</b><br/> Prisciliano Sanchéz Sur #167 Centro, Tepic Nayarit
                                <br/>
                                <br/>
                                <b>Sucursal:</b><br/>
                                López Cotilla #2065 Arcos Vallarta, Guadalajara Jalisco

                            </ContactInfoSmall>
                            <ContactInfo>Teléfono: (33) 1868 9865</ContactInfo>
                            <ContactInfoSmall>Lunes a viernes</ContactInfoSmall>
                            <ContactInfoSmall>9:00 a.m. a 6:00 p.m.</ContactInfoSmall>
                        </div>
                    </div>
                </div>

                
                <div class="col-md-6 col-sm-12 fixingMap">
                    <div class="row">
                        <div class="col-12">
                            <MapContainer title="TestMap"allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d928.0293902632727!2d-104.88965920806932!3d21.50311470000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842737032a87903f%3A0x995ea2d2e5175ed!2sAv.%20Prisciliano%20S%C3%A1nchez%20Sur%20167%2C%20Centro%2C%2063000%20Centro%2C%20Nay.!5e0!3m2!1ses!2smx!4v1661277408808!5m2!1ses!2smx"></MapContainer>
                        </div>
                    </div>
                    <div class="row fixingSocials">
                        <div class="col-6" >
                            <SocialsInfo href="https://www.facebook.com/apipoap" target="_blank"><StyledBsFacebook />    Apipo</SocialsInfo>
                        </div>
                        <div class="col-6">
                            <SocialsInfo href="https://www.instagram.com/apipo.ap/" target="_blank"><StyledSiInstagram />   @apipo.ap</SocialsInfo>
                        </div>
                    </div>
                </div>
            </div>
        </ContactConatiner>
    );
};

export default Contact;