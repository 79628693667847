import React from "react";
import styled from "styled-components";
import { device, deviceMin } from '../assets/device';
import AboutUsBackground from "../assets/images/about-us-background.png";
import AboutUsBackgroundRes from "../assets/images/about-us-background-responsive.png";
import { NavLink as Link } from "react-router-dom";

const AboutContainer = styled.div`
    width: 100%; 
    padding-top: 2em;
    text-align: center;
    background-image: url(${AboutUsBackground});
    color: rgb(30,2,50);
    font-family: Gotham_Medium;

    @media ${device.tablet}{
        background-image: url(${AboutUsBackgroundRes});
        padding-top: 4em;
        padding-bottom: 3em;
    }
`;

const CustomHR = styled.div`
    width: 15%;
    text-align: center;
    border-bottom: 3px solid rgb(30,2,50);
    font-family: Gotham_Medium;

`;

const AboutInfo = styled.div`
    width: 70%;
    padding-top: 2.5em;
    padding-bottom: 4em;
    font-family: Gotham_Medium;

    @media ${device.laptopM}{
        padding-top: 1.5em;
        padding-bottom: 3.5em;
        width: 80%;
    }

    @media ${device.tablet}{
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        width: 90%;
    }
`;

const AboutImg = styled.img`

    text-align: right;
    padding-right: 1em;
    padding-top: 1em;
    height: 35vh;
    width: auto;
    border-radius: 0 5em 5em 5em;

    @media ${device.laptopM}{
        margin-right: 0%;
        margin-top: -15%;
        height: 45vh;
    }

    @media ${device.tablet}{
        text-align: center;
        margin-right: 0%;
        padding-right: 0em;
        height: 24vh;
        margin-bottom: 2em;
        border-radius: 0 3em 3em 3em;
    }

`;

const AboutUsButton = styled(Link)`
    color: rgb(30,2,50);
    background: white;
    padding: 0.8em 3em 0.8em 3em;
    border-radius: 1.5em;
    font-size: 1.2em;
    border: 0px;
    font-family: Gotham_Medium;
    text-decoration: none;
    &:hover {
        background: rgb(30,2,50);
        border: 0px;
        color: white;
    }
`;


function AboutUs() {
    return(
        <AboutContainer id="Nosotros" name="Nosotros" class="container-fluid">    
            <div class="row align-items-center">
                <div class="col-md-12">
                    <h1>Acerca de Nosotros</h1>
                </div>
            </div>
            
            <CustomHR className="container-fluid"/>

            <AboutInfo className="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12 fixingAboutImg" >
                        <AboutImg src={require("../assets/images/nosotros.jpg")}/>
                    </div>
                    <div class="col-md-6 col-sm-12" style={{textAlign:"left",paddingLeft: "1em"}}>
                        <h5>Nuestro propósito es ayudar a las organizaciones grandes o pequeñas a encontrar soluciones efectivas para mejorar la productividad y ser más eficientes.</h5>
                        <br/>
                        <p>Somos una asociación patronal cuyo objetivo es la implementación de nuestros cursos, programas y capacitaciones para ofrecer un valor excepcional y confianza, incentivando de manera económica a nuestros agremiados, así logramos el mejoramiento de sus procesos internos y el cumplimiento de sus objetivos de negocio únicos.</p>
                        <br/>
                        <div style={{textAlign:"center"}}>
                            <AboutUsButton to="/nosotros" >Conoce más</AboutUsButton>
                        </div>
                    </div>
                </div>
            </AboutInfo>

        </AboutContainer>
    );
};

export default AboutUs;