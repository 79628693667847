import React, { useEffect, useState } from "react";
import styled from "styled-components";
import '../assets/style.css';

import { device, deviceMin } from '../assets/device';


const CarouselImg = styled.img`
    object-fit: cover;
    object-position: center;
    height: auto;
    width: 100%;

    @media ${device.tablet}{
        height: 68vh;
    }
`;

const CarouselTitle = styled.p`
    font-size: 6em;
    font-family: GothamBook;
    margin-bottom: 0;
    transition: all 0.5s ease 0s;

    @media ${device.tablet}{
        font-size: 3em;
    }
`;

const CarouselText = styled.p`
    margin-top: 0;    
    font-size: 1.8em;
    font-family: GothamBook;
    padding-bottom: 10em;

    @media ${device.tablet}{
        font-size: 1.1em;
        padding-bottom: 7em;
    }

    @media ${device.laptopM}{
        padding-bottom: 6em;
    }
`;

const CarouselButton = styled.a`
    color: white;
    background: rgb(30,2,50);
    padding: 0.8em 3em 0.8em 3em;
    border-radius: 1.5em;
    font-size: 1.2em;
    border: 0px;
    font-family: Gotham_Medium;
    text-decoration: none;
    &:hover {
        background: rgb(174,83,0);
        border: 0px;
        color: white;
    }
`;

function HomeCarousel() {

    useEffect ( () => {
          let timer = setInterval(() => { document.getElementsByClassName('carousel-control-next-icon')[0].click();}, 5000);
          return () => {
            clearInterval(timer);
          };
    },[]);
        
    return (
        <div id="Inicio" class="carousel slide carousel-fade" data-ride="carousel">
            
            <div class="carousel-inner fixingSize">
                <div class="carousel-item active" data-bs-interval="1000">
                    <CarouselImg src={require('../assets/images/1.png')} alt="..."/>
                    <div class="carousel-caption fixingCaption">
                        <CarouselTitle>APIPO</CarouselTitle> 
                        <CarouselText>Asociación Patronal Impulsora de Productividad de Occidente</CarouselText>
                        <CarouselButton class="btn"  href="#Cursos">Ver Más</CarouselButton>
                    </div>
                </div>

                <div class="carousel-item">
                    <CarouselImg src={require('../assets/images/2.png')} alt="..."/>
                    <div class="carousel-caption fixingCaption" data-bs-interval="1000">
                        <CarouselTitle>APIPO</CarouselTitle>
                        <CarouselText>Asociación Patronal Impulsora de Productividad de Occidente</CarouselText>
                        <CarouselButton class="btn"  href="#Cursos">Ver Más</CarouselButton>
                    </div>
                </div>

                <div class="carousel-item">
                    <CarouselImg src={require('../assets/images/3.png')} alt="..."/>
                    <div class="carousel-caption fixingCaption" data-bs-interval="1000" >
                        <CarouselTitle>APIPO</CarouselTitle>
                        <CarouselText>Asociación Patronal Impulsora de Productividad de Occidente</CarouselText>
                        <CarouselButton class="btn"  href="#Cursos">Ver Más</CarouselButton>
                    </div>
                </div>
            </div>

            <button class="carousel-control-prev" type="button" data-bs-target="#Inicio" data-bs-slide="prev" hidden>
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>

            <button class="carousel-control-next" type="button" data-bs-target="#Inicio" data-bs-slide="next" hidden>
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>

        </div>
    );

}

{
    /*
     <button class="carousel-control-prev" type="button" data-bs-target="#Home" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>

            <button class="carousel-control-next" type="button" data-bs-target="#Home" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>

    */
}


export default HomeCarousel;